<template>
  <component :is="contentComponent" />
</template>

<script>

const _m = () => import('@/pages/qa/m.vue') //代码切割
const _pc = () => import('@/pages/qa/pc.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
    };
  },
  computed: {
    ...mapGetters([
      'deviceType',
    ])
  },
  metaInfo () {
    return {
      title: 'Frequently Asked Questions - thebookofanswer.info',
      meta: [
        {
          name: 'description',
          content: 'Find answers to common questions about thebookofanswer.info. Learn how to use our AI-powered platform, understand our data collection practices, and get tips on how to get the most accurate and reliable answers. If you have any additional questions, feel free to reach out to us."',
        },
      ],
    };
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = _pc
      return
    }
    this.contentComponent = _m
  },
};
</script>
